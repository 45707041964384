import React from "react";

function ExploreOpen() {
  return (
    <div className="bg-grey-normal">
      <div className="px-8 py-12 m-auto max-w-7xl md:py-24">
        <div className="flex justify-center w-full">
          <h1 className="mb-8 text-4xl font-medium lg:text-5xl md:mb-0 ">
            Explore open roles
          </h1>
        </div>
        <div className="grid grid-cols-1 gap-10 my-5 md:grid-cols-2">
          <div className="flex-1 py-3 bg-white border-0 outline-none select">
            <select className="px-3 bg-white cursor-pointer">
              <option value="1">ALL locations</option>
              <option value="2">1</option>
              <option value="3">2</option>
            </select>
          </div>
          <div className="px-3 py-3 bg-white cursor-pointer select">
            <select className="bg-white">
              <option value="1">ALL Careers</option>
              <option value="2">1</option>
              <option value="3">2</option>
            </select>
          </div>
        </div>
        <div className="flex justify-center pt-5 md:justify-start">
          <a
            href="/"
            className="px-6 py-6 mt-5 text-base font-semibold text-white bg-black rounded-lg"
          >
            Searh Jobs
          </a>
        </div>
      </div>
    </div>
  );
}

export default ExploreOpen;
