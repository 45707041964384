import React from "react";
import Headerp from "./../../images/Community-Hub.png";

function Header() {
  return (
    <div className="header-gradient">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white"> CAREERS</span>
            </div>
            <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
              Be what's next. Right Now.
            </h1>
            <h3 className="md:text-2xl text-xl leading-6 font-bold text-white mb-4">
              Vonage, a global cloud communications leader, helps businesses
              accelerate their digital transformation.
            </h3>
            <p className="md:text-base text-sm  font-normal text-white mb-5">
              Our Communications Platform is fully programmable and allows for
              the integration of Video, Voice, Chat, Messaging and Verification
              into existing products, workflows and systems.
            </p>

            <p className="md:text-base text-sm font-normal text-white mb-5">
              Our fully programmable unified communications and contact center
              applications are built from the Vonage platform and enable
              companies to transform how they communicate and operate from the
              office or anywhere, providing enormous flexibility and ensuring
              business continuity.
            </p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
