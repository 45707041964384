import React from "react";
import Careersatvonage from "./Careersatvonage";
import Header from "./Header";
import InclusionandFairness from "./InclusionandFairness";
import Exploreopenroles from "./Exploreopenroles";
import ExploreLife from "./ExploreLife";
import Cards from "./Cards";
import data from "./../../data";
import ExploreOpen from "./ExploreOpen";

function Index({location}) {
  return (
    <div>
      <Header />
      <ExploreOpen />
      <Cards card3={data.card3} />
      <Exploreopenroles />
      <ExploreLife />
      <Careersatvonage />
      <InclusionandFairness />
    </div>
  );
}

export default Index;
