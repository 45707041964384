import React from "react";
import { BsArrowRight } from "react-icons/bs";

function ExploreLife() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div className="bg-black md:py-32 py-24 md:px-20 px-14 text-white info-card">
            <h1 className="mb-4 font-black text-3xl">Explore life at Vonage</h1>
            <p className="text-lg font-normal mb-12">
              Learn more about benefits, employee programs and diversity and
              inclusion at Vonage.
            </p>
            <BsArrowRight className="text-4xl" />
          </div>
          <div className="bg-black md:py-32 py-24 md:px-20 px-14 text-white info-card">
            <h1 className="mb-4 font-black text-3xl">
              The candidate experience
            </h1>
            <p className="text-lg font-normal mb-12">
              Learn about the Vonage Behaviors and meet our talent acquisition
              team.
            </p>
            <BsArrowRight className="text-4xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreLife;
