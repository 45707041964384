import React from 'react';

function InclusionandFairness() {
    return (
        <div>
            <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
                <h6 className="text-xs font-bold mb-4">Inclusion and Fairness at Vonage</h6>
                    <p className="text-xs font-normal leading-4">At Vonage, we celebrate diversity, as it is in the best interest
                    of our employees, customers, and community to do so! Vonage is proud
                    to be an equal opportunity workplace. We are committed to equal employment opportunity for all, including equivalent pay, and will not discriminate against an applicant or an employee based on gender/gender
                    identity, race, color, ancestry/ethnicity, religion/conviction of belief,
                    creed, national origin, sexual orientation, genetic information, age,
                    citizenship, marital status, pregnancy, disability, military status, 
                    political beliefs, family status and any other category protected by
                    applicable law. If you would like to see a copy of our EEO policy, 
                    or if you have a disability or special need that requires accommodation, 
                    please let us know by emailing CandidateAccommodations@vonage.com.
                    </p>
            </div>
        </div>
    )
}

export default InclusionandFairness;