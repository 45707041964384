import React from "react";

const Cards = ({ card3 }) => {
  return (
    <div className="px-8 py-14 mb-24 ">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-items-center  md:mt-16 mt-8 gap-10">
        {card3.map((item, index) => {
          return (
            <div >
              <Card3
                index={index}
                name={item.title}
                link={item.link}
                img={item.img}
                desc={item.desc}
                src={item.src}
                btn={item.btn}
                // icons={item.icons}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;

const Card3 = ({ name, link, desc, img, btn, icons }) => {
  return (
    <div className="mb-20 ">
      {" "}
      {/* <Icon>{icons}</Icon> */}
      <div className="relative lg:mt-0 mt-20 md:mb-30 mb-10">
        <div>
          <img src={img} alt="" className="w_400" />
        </div>
        <div className="bg-white  absolute z-10 -mt-12 md:w-11/12 w-full box-border py-5">
          <h1 className="text-xs mb-3 uppercase tracking-widest font-normal leading-5 ">
            {name}
          </h1>
          <p className="text-xs mb-3 font-normal text-grey-dark tracking-widest">
            {desc}
          </p>
          <div >
            <a href={link}>
              <span className="md:text-xl text-lg text-black">{btn}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
