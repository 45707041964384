import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Exploreopenroles extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    return (
        <div className="max-w-4xl m-auto md:py-24 py-12 px-8">
            <div className="w-full flex justify-center">
                <h1 className="text-2xl font-bold text-black">Explore open roles by region</h1>
            </div>
      <div className="relative mt-10">
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            //   bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
       </div>
      </div>
    );
  }
}

export default Exploreopenroles;

